import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslationService } from 'src/app/services/translation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ServerService } from 'src/app/services/server.service';
import { UserContextService } from 'src/app/services/user-context.service';
import _ from 'lodash';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private titleService: Title, private loginService: LoginService,
    private router: Router, private translation: TranslationService, private navigation: NavigationService,
    private server: ServerService, public userContext: UserContextService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit(): void {
    if (this.navigation.checkLogin("", "Hovedside")) {
    }
  }

  logout() {
    this.loginService.logout()
    window.location.href = "/"
  }

  goto(url) {
    this.navigation.goToUrl(url, undefined)
  }

  async repeat() {
    await this.userContext.fetchAccounts()
    let account = this.userContext.getAccount()
    let ordersResponse = await this.server.getOrders(account)
    let orders = ordersResponse.data
    if (orders) {
      if (orders.length === 0) {
        this.goto("/create-order")
      } else {
        let sorted = _.orderBy(orders, "createdOn", "desc")
        let url = "/copy/" + sorted[0].orderId
        this.goto(url)
      }
    }
  }
}
