import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    constructor() {

    }

    handleError(error: Error) {
        console.error('CustomErrorHandler', error);
        if (error instanceof HttpErrorResponse) {
            if (!navigator.onLine) {
                this.panic("no-internet")
            } else {
                switch (error.status) {
                    case 401:
                        this.login()
                        break;
                    case 403:
                        this.panic("forbidden")
                        break;
                    case 500:
                        this.panic("server")
                        break;
                    default:
                        this.panic("connection")
                        break;
                }
            }
        } else {
            this.panic("")
        }
    }


    panic(type) {
        // if (type) {
        //     window.location.href = "/error/" + type
        // } else {
        //     window.location.href = "/error"
        // }
    }

    login() {
        window.location.href = "/login"
    }
}