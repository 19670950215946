import { Component, OnInit } from '@angular/core';
import { pause, download } from 'src/app/common/tools';
import { CrumbStep } from 'src/app/widgets/crumbs/crumbs.component';
import { UserContextService } from 'src/app/services/user-context.service';
import { ServerService } from 'src/app/services/server.service';
import { CustomSnackBarService } from 'src/app/services/custom-snack-bar.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {
  buzy: boolean = true;

  _account: string = "";
  documents: any[];


  path: CrumbStep[] = [
    { text: "Vilkår", target: "" },
    { text: "Avtale/Kontrakt", target: "" }
  ];

  constructor(public userContext: UserContextService, private server: ServerService,
    private snackBar: CustomSnackBarService, private navigation: NavigationService,
    private translation: TranslationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }


  ngOnInit() {
    let url = "/contract"
    if (this.navigation.checkLogin(url, "Avtale/Kontrakt")) {
      this.loadDocuments()
    }
  }

  async loadDocuments() {
    await this.userContext.fetchAccounts()
    this._account = this.userContext.getAccount()

    await this.loadDocumentsForAccount()

    this.buzy = false
  }

  get account() {
    return this._account
  }

  set account(value) {
    this._account = value
    this.userContext.setAccount(value)
    this.loadDocumentsForAccount()
  }

  async loadDocumentsForAccount() {
    let docsResult = await this.server.getAccountDocuments(this.account)
    this.documents = docsResult.data
  }

  async downloadFile(doc) {
    let content = await this.server.downloadAccountDocument(doc.attachmentId)
    download(content.data)
  }
}
