import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-expandable-table',
  templateUrl: './expandable-table.component.html',
  styleUrls: ['./expandable-table.component.css']
})
export class ExpandableTableComponent implements OnInit {
  @Input()
  subobject: string;

  @Input()
  checkbox: boolean = false;

  @Input()
  sortBy: number = 0;

  @Input()
  firstWrapped: number = 3;

  @Input()
  columns: ExpandableTableColumn[];

  @Input()
  subcolumns: ExpandableTableColumn[];

  @Input()
  rows: any[];

  checkAllChecked: boolean = false

  _account: string;

  @Input()
  set account(account) {
    if (this._account !== account) {
      this._account = account
      this.checkAllChecked = false
      this.selected = {}
      this.onSelectionChanged.emit([])
    }
  }

  @Output() onSelectionChanged = new EventEmitter<any>()
  @Output() onRowExpanded = new EventEmitter<any>()

  visibleColumn: number = 1;

  selected: any = {};

  constructor(private translation: TranslationService, private popup: PopupService) { }

  tr(textId: string): string {
    return this.translation.translate(textId)
  }

  ngOnInit() {
  }

  getSelectableColumns() {
    let columns = this.columns.slice(1, this.columns.length - 1)
    return columns
  }

  onColumnChanged(event) {
    this.visibleColumn = event.value
  }

  onCellClick(event, column, row) {
    if (column.type === 'menu' && column.menu) {

      let items = column.menu(row)
      this.popup.showClickPopup(event.target, event.pageX, event.pageY, items)

    } else if (column.expander) {
      row.expanded = !row.expanded

      if (row.expanded) {
        this.onRowExpanded.emit(row)
      }
    }
  }

  getAlign(column) {
    if (column.type === "number" || column.type === "currency") {
      return "right"
    }

    return "left"
  }

  changeSelection(event, row) {
    let checked = event.target.checked
    if (checked) {
      this.selected[row.orderId] = true
    } else {
      delete this.selected[row.orderId]
    }

    let selected = this.getSelection()
    this.onSelectionChanged.emit(selected)
  }

  hasSelection() {
    let selected = this.getSelection()
    return selected.length > 0
  }

  isSelected(row) {
    return row.orderId in this.selected
  }

  getSelection() {
    return Object.keys(this.selected)
  }

  toggleSelectAll(event) {
    if (event.target.checked) {
      for (let row of this.rows) {
        this.selected[row.orderId] = true
      }
    } else {
      this.selected = {}
    }

    this.checkAllChecked = event.target.checked

    this.rows = this.rows

    let selected = this.getSelection()
    this.onSelectionChanged.emit(selected)
  }

  shouldWrap(column) {
    let index = this.columns.indexOf(column)
    return (index >= this.firstWrapped)
  }
}

export class ExpandableTableColumn {
  name: string;
  fieldName: string;
  type: string;
  expander: boolean;
  menu: any;
  width: number;
  mwidth: string;
  mobile: boolean;
}

export class ExpandableTableMenuItem {
  text: string;
  action: any;
}
