import { Component, OnInit } from '@angular/core';
import { CrumbStep } from 'src/app/widgets/crumbs/crumbs.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  path: CrumbStep[] = [
    { text: "Kontakt", target: "" },
    { text: "Om oss", target: "" }
  ];


  constructor(private translation: TranslationService, private navigation: NavigationService,
  ) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit(): void {
    if (this.navigation.checkLogin("/about", "Om oss")) {
    }
  }

  nb() {
    return this.translation.language === "nb"
  }

  en() {
    return this.translation.language === "en"
  }

  da() {
    return this.translation.language === "da"
  }
}
