import { Injectable } from '@angular/core';
import Messages from '../../assets/messages.json';
import { ServerService, Contact } from 'src/app/services/server.service';
import { normalizeLanguage } from '../common/tools.js';
import { PopupService } from './popup.service.js';

const defaultLanguage = "nb"

@Injectable({
  providedIn: 'root'
})

export class TranslationService {
  language: string;

  constructor(private server: ServerService) {
    this.language = this.chooseLanguage(undefined)
  }

  translate(text: string): string {
    if (this.language === defaultLanguage) {
      return text
    }

    let bucket = Messages[text]
    if (bucket) {
      let translation = bucket[this.language]
      if (translation) {
        return translation
      }
    }

    // console.error(`Translation not found. Language: ${this.language}. String: ${text}`)
    return text
  }

  switchLanguage(language: string): void {
    this.setLanguage(language)
    this.saveLanguageInDb(language)
  }

  setLanguage(language: string): void {
    localStorage.setItem("language", language)
    this.language = language
  }

  saveLanguageInDb(language: string): void {
    let contact = new Contact()
    contact.preferredLanguage = language
    this.server.saveContactInformation(contact)
  }

  chooseLanguage(dbLanguage: string): string {
    if (dbLanguage) {
      return dbLanguage
    }

    let localLanguage = localStorage.getItem("language")
    if (localLanguage) {
      localLanguage = normalizeLanguage(localLanguage)
      return localLanguage
    }

    let browserLanguage = normalizeLanguage(navigator.language)
    switch (browserLanguage) {
      case "1033":
      case "1044":
      case "1030":
        return browserLanguage
      default:
        return "1044"
    }
  }
}

