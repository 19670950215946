import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { get } from 'src/app/common/tools';

const left = 2
const right = 4
const idleTimeout = 5000
const changeTimeout = 1000

const width = 840


@Component({
  selector: 'app-news-carousel',
  templateUrl: 'news-carousel.component.html',
  styleUrls: ['news-carousel.component.css'],
})
export class NewsCarouselComponent implements OnInit, OnDestroy {

  @ViewChild('primary', { static: false })
  primary: ElementRef;

  @ViewChild('primaryImg', { static: false })
  primaryImg: ElementRef;

  @ViewChild('secondary', { static: false })
  secondary: ElementRef;

  @ViewChild('secondaryImg', { static: false })
  secondaryImg: ElementRef;

  slides: any[] = [
    { img: "../../../assets/img/fisk.jpg", text: "Lysbilde tekst 1" },
    { img: "../../../assets/img/haug.jpg", text: "Lysbilde tekst 2" },
    { img: "../../../assets/img/lager.jpg", text: "Lysbilde tekst 3" },
    { img: "../../../assets/img/vei.jpg", text: "Lysbilde tekst 4" }
  ]

  state: string = "idle"
  current: number = 0;
  timer: any;

  mover: Mover = new Mover(this.slides.length);

  constructor(private translation: TranslationService) {
  }

  tr(textId: string): string {
    return this.translation.translate(textId)
  }


  ngOnInit() {
    this.startIdleTimer()
  }

  ngAfterViewInit() {
    this.mover.still(this.current)
  }

  ngOnDestroy() {
    this.mover.still(this.current)
    this.stopTimer()
  }

  clickDot(id) {
    if (this.state === "idle") {
      this.current = id
      this.stopTimer()
      this.startIdleTimer()
      this.mover.still(this.current)
    }
  }

  getNext() {
    return (this.current + 1) % this.slides.length
  }

  getPrev() {
    if (this.current === 0) {
      return this.slides.length - 1
    } else {
      return this.current - 1
    }
  }

  startIdleTimer() {
    this.stopTimer()
    this.timer = setTimeout(() => this.idleOver(), idleTimeout)
  }

  startChangeTimer() {
    this.stopTimer()
    this.timer = setTimeout(() => this.changeOver(), changeTimeout)
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = undefined
    }
  }

  swipe(event) {
    if (event.direction === left) {
      this.swipeLeft()
    } else if (event.direction === right) {
      this.swipeRight()
    }
  }

  async changeRight() {
    this.state = "change-right"
    this.startChangeTimer()
    this.mover.leftToRight()
  }

  changeLeft() {
    this.state = "change-left"
    this.startChangeTimer()
    this.mover.rightToLeft()
  }

  swipeLeft() {
    if (this.state === "idle") {
      this.changeLeft()
    }
  }

  swipeRight() {
    if (this.state === "idle") {
      this.changeRight()
    }
  }

  idleOver() {
    this.changeLeft()
  }

  changeOver() {
    if (this.state === "change-right") {
      this.current = this.getPrev()
      this.state = "idle"
      this.startIdleTimer()
      this.mover.still(this.current)
    } else if (this.state === "change-left") {
      this.current = this.getNext()
      this.state = "idle"
      this.startIdleTimer()
      this.mover.still(this.current)
    }
  }
}

class Mover {
  positions: number[];

  constructor(length: number) {
    this.positions = []
    for (let i = 0; i < length; i++) {
      this.positions.push(i)
    }
  }

  still(current) {
    let length = this.positions.length
    current = (current + length - 1) % length
    for (let i = 0; i < length; i++) {
      let next = (i + current) % length
      this.positions[i] = next
    }

    let left = -width
    for (let i = 0; i < length; i++) {
      let ordinal = this.positions[i]
      positionElement(ordinal, left)
      left += width
    }
  }

  moveElements(left) {
    let length = this.positions.length
    for (let i = 0; i < length; i++) {
      let ordinal = this.positions[i]
      moveElement(ordinal, left)
      left += width
    }
  }

  leftToRight() {
    this.moveElements(0)
  }

  rightToLeft() {
    this.moveElements(-width * 2)
  }
}

function getSlideElement(ordinal) {
  let id = "carousel_" + ordinal
  return get(id)
}

function moveElement(ordinal, left) {
  let element = getSlideElement(ordinal)
  if (element) {
    let time = changeTimeout / 1000 * 0.95
    element.style.transition = "left " + time + "s"
    element.style.left = left + "px"
  }
}

function positionElement(ordinal, left) {
  let element = getSlideElement(ordinal)
  if (element) {
    element.style.transition = "none"
    element.style.left = left + "px"
  }
}
