import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslationService } from './translation.service';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  title: string = "";

  constructor(public loginService: LoginService,
    public titleService: Title, public router: Router, public translation: TranslationService) { }


  setTitle(title: string) {
    this.title = title
    this.updateTitle()
  }

  updateTitle() {
    this.titleService.setTitle("GC Rieber Salt - " + this.translation.translate(this.title))
  }



  checkLogin(path: string, title: string): boolean {
    if (!this.loginService.isLoggedIn()) {
      this.router.navigate(["/login"], { state: { target: path } })
      return false
    } else {
      this.setTitle(title)
      return true
    }
  }

  goToUrl(url: string, state: any) {
    if (state === undefined) {
      this.router.navigate([url])
    } else {
      this.router.navigate([url], { state: state })
    }
  }
}
