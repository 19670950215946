import { Component, OnInit } from '@angular/core';
import { ServerService } from './../../services/server.service';

const doc1 = "<h1>Corsair long clothes starboard tender</h1> " +
  "<p><img alt=\"Fastest dog\" " +
  "        src=\"https://s3.amazonaws.com/cdn-origin-etr.akc.org/wp-content/uploads/2019/04/29112010/running-2.jpg\"></p> " +
  "<p>Black spot aft <span style=\"color:#1abc9c;\">scallywag bounty</span> overhaul shrouds execution dock stern wherry " +
  "    hail-shot. Flogging fire ship squiffy " +
  "    Plate Fleet Chain Shot brig Nelsons folly tender list dead men tell no tales. Fire in the hole poop deck code of " +
  "    conduct lateen sail pressgang Sea Legs lugsail hands ahoy Nelsons folly.</p> " +
  "<p>Handsomely lateen sail Plate Fleet <span style=\"color:#1abc9c;\">Admiral of the Black parrel</span> nipper boom heave " +
  "    down hornswaggle pinnace. Spanish " +
  "    Main league topgallant Jack Ketch me black jack bilge lanyard Davy Jones' Locker pillage. Privateer driver transom " +
  "    gally Jolly Roger run a shot across the bow sheet boatswain gangway fore.</p> " +
  "<p>Handsomely league chandler hornswaggle starboard boom booty ye rope's end American Main. Measured fer yer chains " +
  "    Pirate Round case shot grapple warp me matey pressgang grog marooned. Hornswaggle Admiral of the Black parley haul " +
  "    wind schooner grapple Corsair hands case shot topgallant.</p> " +
  "<h2>Run a rig barkadeer</h2> " +
  "<p>Starboard Plate Fleet rutters swing the lead hogshead bounty swab overhaul transom draft. Marooned Nelsons folly Sail " +
  "    ho handsomely gunwalls code of conduct jolly boat carouser ballast heave to. Transom aft cog run a rig sheet " +
  "    measured fer yer chains lugsail lee Corsair lad.</p> " +
  "<p>Belay gaff <span style=\"color:#1abc9c;\">Plate Fleet driver</span> draught spirits hearties draft yardarm prow. " +
  "    Skysail sloop dead men tell no tales " +
  "    pinnace Plate Fleet coffer smartly careen log long clothes. Reef sails furl deadlights heave to careen fathom " +
  "    cutlass matey topsail lookout.</p> " +
  "<p>Run a shot across the bow pressgang Spanish Main grog black jack pinnace blow the man down measured fer yer chains " +
  "    hempen halter heave to. Blow the man down draught tender weigh anchor matey heave to to go on account carouser " +
  "    handsomely gaff. Chandler pillage take a caulk ahoy run a shot across the bow Davy Jones' Locker prow holystone Jack " +
  "    Ketch boom. </p> " +
  "<h2>Grapple rigging Jack Ketch piracy port</h2> " +
  "<p>Driver keel matey strike colors avast knave landlubber or just lubber hands grog chase guns. Mutiny jib rigging code " +
  "    of conduct ahoy aye Sail ho hempen halter Cat o'nine tails to go on account. Hang the jib Sail ho scourge of the " +
  "    seven seas rigging grapple scurvy wench stern doubloon quarter. </p> " +
  "<p>Walk the plank blow the man down skysail cutlass transom booty cable pillage starboard lugger. Gabion spanker run a " +
  "    rig clipper crow's nest cackle fruit to go on account parley wench black spot. Admiral of the Black rope's end " +
  "    skysail splice the main brace poop deck starboard haul wind capstan holystone reef sails. </p> " +
  "<p>Hearties Sink me scuppers pink poop deck jury mast crow's nest schooner yo-ho-ho bring a spring upon her cable. Aye " +
  "    to go on account gally reef avast crow's nest rope's end Pieces of Eight spyglass measured fer yer chains. Broadside " +
  "    jolly boat crow's nest reef sails man-of-war walk the plank gaff swing the lead quarter starboard. </p> "


@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.css']
})
export class DocViewerComponent implements OnInit {

  docs = {
    "doc1": doc1
  }



  constructor(private server: ServerService) { }

  ngOnInit() {
    this.fetchFile()
  }

  async fetchFile() {
    let attachmentId = "6832db28-4e96-8713-91f4-eae6effe4b11"
    let content = await this.server.downloadDocument(attachmentId)
    let a = document.getElementById("super-puper")
    a.innerText = content.data.fileName
    let binary = base64ToArrayBuffer(content.data.documentBody)
    let blob = new Blob([binary], { type: content.data.mimeType })
    var url = window.URL.createObjectURL(blob)
    a["href"] = url
    a["download"] = content.data.fileName
    console.log(content)
  }

}


function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}