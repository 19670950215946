import { Component, OnInit, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  @ViewChildren('login_email') emailControl: QueryList<ElementRef>;
  @ViewChildren('login_password') passwordControl: QueryList<ElementRef>;

  target: string = "";

  loginForm = new FormGroup({
    email: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required)
  })

  error: String = "";
  busy: boolean = false;

  constructor(private titleService: Title, private loginService: LoginService,
    private router: Router, private translation: TranslationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
    this.target = history.state.target || "/"
    this.titleService.setTitle("GC Rieber Salt - " + this.tr("Logg inn"))
  }

  ngAfterViewInit() {
    setTimeout(() => this.getEmail().nativeElement.focus(), 500)
  }

  getEmail() {
    return this.emailControl.first
  }

  getPassword() {
    return this.passwordControl.first
  }

  async startLogin() {
    if (this.busy) {
      return
    }

    if (this.getEmail().nativeElement === document.activeElement) {
      this.getPassword().nativeElement.focus()
      return
    }

    if (this.loginForm.controls["email"].status != "VALID") {
      this.getEmail().nativeElement.focus()
      this.error = this.tr("Vennligst tast inn epostaddresse")
      return
    } else if (this.loginForm.controls["password"].status != "VALID") {
      this.getPassword().nativeElement.focus()
      this.error = this.tr("Vennligst tast inn passord")
      return
    }

    this.busy = true
    this.error = ""
    let loginResult = await this.loginService.login(
      this.getEmail().nativeElement.value.trim(),
      this.getPassword().nativeElement.value
    )

    if (loginResult.success) {
      // this.router.navigate(["/"]) 
      this.router.navigate([this.target])
    } else {
      this.error = this.getErrorMessage(loginResult.message)
    }
    this.busy = false
  }

  getErrorMessage(message: string): string {
    if (message) {
      if (message.startsWith("AADB2C90225")) {
        message = "Feil epost eller passord"
      }
    } else {
      message = "Det oppsto en feil"
    }

    return this.tr(message)
  }
}
