import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { RouterModule, Routes } from '@angular/router'
import { MatButtonModule, MatSnackBarModule, MatSelectModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material';
import { MatDialogModule } from "@angular/material";
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppComponent } from './app.component';
import { MainComponent } from './screens/main/main.component';
import { LoginComponent } from './screens/login/login.component';
import { PageNotFoundComponent } from './screens/page-not-found/page-not-found.component';
import { ShellComponent } from './widgets/shell/shell.component';
import { ProfileComponent } from './screens/profile/profile.component';
import { DonutComponent } from './widgets/donut/donut.component';
import { MobileMenuComponent } from './widgets/mobile-menu/mobile-menu.component';
import { OrdersComponent } from './screens/orders/orders.component';
import { CrumbsComponent } from './widgets/crumbs/crumbs.component';
import { ExpandableTableComponent } from './widgets/expandable-table/expandable-table.component';
import { ResetPasswordComponent } from './screens/reset-password/reset-password.component';
import { SetPasswordComponent } from './screens/set-password/set-password.component';
import { IncomingOrdersComponent } from './screens/incoming-orders/incoming-orders.component';
import { DocViewerComponent } from './widgets/doc-viewer/doc-viewer.component';
import { CreateOrderComponent } from './screens/create-order/create-order.component';
import { AddressPickerComponent } from './widgets/address-picker/address-picker.component';
import { OrderLinesComponent } from './widgets/order-lines/order-lines.component';
import { ProductLookupComponent } from './widgets/product-lookup/product-lookup.component';
import { NewsCarouselComponent } from './widgets/news-carousel/news-carousel.component';
import { DataSheetComponent } from './screens/data-sheet/data-sheet.component';
import { TermsComponent } from './screens/terms/terms.component';
import { AboutComponent } from './screens/about/about.component';
import { ContractComponent } from './screens/contract/contract.component';
import { ErrorComponent } from './screens/error/error.component';
import { CustomErrorHandler } from './common/custom-error-handler';
import { CustomerServiceComponent } from './screens/customer-service/customer-service.component';
import { OrderLinesMoneyComponent } from './widgets/order-lines-money/order-lines-money.component';


const appRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "profile", component: ProfileComponent },
  { path: "orders", component: OrdersComponent, data: { mode: "open-orders" } },
  { path: "history/:orderId", component: CreateOrderComponent, data: { mode: "view" } },
  { path: "create-order", component: CreateOrderComponent, data: { mode: "normal" } },
  { path: "orders/:orderId/edit", component: CreateOrderComponent, data: { mode: "edit" } },
  { path: "orders/:orderId", component: CreateOrderComponent, data: { mode: "confirmation" } },
  { path: "copy/:orderId", component: CreateOrderComponent, data: { mode: "copy" } },
  { path: "confirm/:orderId", component: CreateOrderComponent, data: { mode: "customer" } },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "set-password/:token", component: SetPasswordComponent },
  { path: "incoming-orders", component: IncomingOrdersComponent },
  { path: "customer-service", component: CustomerServiceComponent },
  { path: "data-sheet", component: DataSheetComponent },
  { path: "doc/:docId", component: DocViewerComponent },
  { path: "terms", component: TermsComponent },
  { path: "about", component: AboutComponent },
  { path: "contract", component: ContractComponent },
  { path: "error/no-internet", component: ErrorComponent, data: { message: "Ingen internettforbindelse" } },
  { path: "error/connection", component: ErrorComponent, data: { message: "Tilkoblingsfeil" } },
  { path: "error/server", component: ErrorComponent, data: { message: "Serverfeil" } },
  { path: "error/forbidden", component: ErrorComponent, data: { message: "Ingen tilgang" } },
  { path: "error", component: ErrorComponent },
  { path: "history", component: OrdersComponent, data: { mode: "history" } },
  { path: "", component: MainComponent },
  { path: "**", component: PageNotFoundComponent }
]

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    PageNotFoundComponent,
    ShellComponent,
    ProfileComponent,
    DonutComponent,
    MobileMenuComponent,
    OrdersComponent,
    CrumbsComponent,
    ExpandableTableComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    IncomingOrdersComponent,
    DocViewerComponent,
    CreateOrderComponent,
    AddressPickerComponent,
    OrderLinesComponent,
    ProductLookupComponent,
    DataSheetComponent,
    NewsCarouselComponent,
    TermsComponent,
    AboutComponent,
    ContractComponent,
    ErrorComponent,
    CustomerServiceComponent,
    OrderLinesMoneyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    MatDialogModule,
    MatTooltipModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false, useHash: false })
  ],

  providers: [
    Title,
    MatDatepickerModule,
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ProductLookupComponent]
})
export class AppModule {

}

