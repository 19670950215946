import { Component, OnInit, Input, Inject } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import _ from 'lodash';
import { findByProp } from './../../common/tools';
import { replaceAll } from './../../common/tools';

const LastUsed = "Sist brukt"
const AllProducts = "Alle produkter"

@Component({
  selector: 'app-product-lookup',
  templateUrl: './product-lookup.component.html',
  styleUrls: ['./product-lookup.component.css']
})
export class ProductLookupComponent implements OnInit {


  searchString: string = "";
  searchStringCurrent: string = "";
  category: string = "";
  categories: any[];
  products: any[];
  lastProducts: any[];
  debouncedSearch: any;
  lines: any[];

  constructor(
    private translation: TranslationService,
    private dialogRef: MatDialogRef<ProductLookupComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.products = data.products
    this.lastProducts = data.lastProducts
    this.categories = data.categories
    this.category = data.category
    this.lines = data.lines

    if (this.lastProducts.length > 0) {
      this.categories.unshift(LastUsed)
    }
    if (!this.category) {
      this.category = this.categories[0]
    }
    this.categories.unshift(AllProducts)
  }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
    this.cancelSearch()
    this.doSearch()
  }

  getCategories() {
    return this.categories
  }

  searchInProducts() {
    var selectedProducts
    if (this.category === LastUsed) {
      selectedProducts = this.lastProducts.filter(product => productMatches(
        product,
        product.category,
        this.searchStringCurrent,
        this.lines))
    } else if (this.category === AllProducts) {
      selectedProducts = this.products.filter(product => allProductMatches(
        product,
        this.searchStringCurrent,
        this.lines))
    } else {
      selectedProducts = this.products.filter(product => productMatches(
        product,
        this.category,
        this.searchStringCurrent,
        this.lines))
    }



    return _.orderBy(selectedProducts, ["name"])
  }



  close() {
    this.dialogRef.close({})
  }

  chooseProduct(product) {
    this.cancelSearch()
    this.dialogRef.close(
      {
        productId: product.id,
        category: this.category
      }
    )
  }


  onSearch() {
    if (this.debouncedSearch) {
      this.debouncedSearch()
    }
  }

  cancelSearch() {
    if (this.debouncedSearch) {
      this.debouncedSearch.cancel()
      this.debouncedSearch = undefined
    }
    this.debouncedSearch = _.debounce(() => this.doSearch(), 300)
  }

  doSearch() {
    this.searchStringCurrent = this.searchString
  }

  printCategory(category) {
    if (category === LastUsed) {
      return this.tr(category)
    } else {
      return replaceAll(category, "_", " ")
    }
  }
}


function productMatches(product, category, searchString, lines) {
  let lowSearch = searchString.trim().toLowerCase()
  if (product.category === category) {
    if (findByProp(lines, "id", product.id)) {
      return false
    }

    if (!searchString) {
      return true
    }

    if (containsLowCase(product.extProductNo, lowSearch)) {
      return true
    }

    if (containsLowCase(product.productNumberAX, lowSearch)) {
      return true
    }

    if (containsLowCase(product.name, lowSearch)) {
      return true
    }
  }

  return false
}

function allProductMatches(product, searchString, lines) {
  let lowSearch = searchString.trim().toLowerCase()

  if (findByProp(lines, "id", product.id)) {
    return false
  }

  if (!searchString) {
    return true
  }

  if (containsLowCase(product.extProductNo, lowSearch)) {
    return true
  }

  if (containsLowCase(product.productNumberAX, lowSearch)) {
    return true
  }

  if (containsLowCase(product.name, lowSearch)) {
    return true
  }

  return false
}


function containsLowCase(text: string, lowSearch: string): boolean {
  let lowText = text || ""
  lowText = lowText.toLowerCase()
  return lowText.indexOf(lowSearch) != -1
}

