import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslationService } from 'src/app/services/translation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ServerService } from 'src/app/services/server.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  state: string = "ready";

  resetForm = new FormGroup({
    email: new FormControl("", Validators.required)
  })

  error: String = "";



  constructor(private navigation: NavigationService, private translation: TranslationService,
    private server: ServerService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }


  ngOnInit() {
    this.navigation.setTitle("Tilbakestille passord")
  }

  goBack() {
    window.history.back()
  }

  async reset() {
    if (this.state != "ready") {
      return
    }

    this.error = ""

    if (this.resetForm.controls["email"].status != "VALID") {
      this.error = this.tr("Vennligst tast inn epostaddresse")
      return
    }

    let email = this.resetForm.controls["email"].value.trim()

    this.state = "sending"
    var success = true

    try {
      success = await this.server.resetPassword(email)
    } catch {
      success = false
    }

    if (success) {
      this.state = "success"
    } else {
      this.state = "ready"
      this.error = this.tr("Det oppsto en feil")
    }
  }
}
