import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';

export class ObservableResult {
    data: any;
    error: any;
}

function makeOptions(headers: any) {
    headers = headers || {}
    let options = {
        headers: new HttpHeaders(headers)
    }
    return options
}

export async function sendPost(http: HttpClient, url: string, body: any, headers: any): Promise<ObservableResult> {
    let callback = function (resolve) {
        let observer = {
            next(payload) { resolve({ data: payload }) }
        }

        let options = makeOptions(headers)
        http.post(url, body, options).subscribe(observer)
    }

    return new Promise<ObservableResult>(callback)
}

export async function sendPostRaw(http: HttpClient, url: string, body: any, headers: any): Promise<ObservableResult> {
    let callback = function (resolve) {
        let observer = {
            next(payload) { resolve({ data: payload }) },
            error(payload) { resolve({ error: payload }) }
        }

        let options = makeOptions(headers)
        http.post(url, body, options).subscribe(observer)
    }

    return new Promise<ObservableResult>(callback)
}

export async function sendPut(http: HttpClient, url: string, body: any, headers: any): Promise<ObservableResult> {
    let callback = function (resolve) {
        let observer = {
            next(payload) { resolve({ data: payload }) }
        }

        let options = makeOptions(headers)
        http.put(url, body, options).subscribe(observer)
    }

    return new Promise<ObservableResult>(callback)
}

export async function sendPatch(http: HttpClient, url: string, body: any, headers: any): Promise<ObservableResult> {
    let callback = function (resolve) {
        let observer = {
            next(payload) { resolve({ data: payload }) }
        }

        let options = makeOptions(headers)
        http.patch(url, body, options).subscribe(observer)
    }

    return new Promise<ObservableResult>(callback)
}

export async function sendGet(http: HttpClient, url: string, headers: any): Promise<ObservableResult> {
    let callback = function (resolve) {
        let observer = {
            next(payload) { resolve({ data: payload }) }
        }

        let options = makeOptions(headers)
        http.get(url, options).subscribe(observer)
    }

    return new Promise<ObservableResult>(callback)
}

export function getUnixTime(): number {
    let milliseconds = new Date().getTime()
    return Math.floor(milliseconds / 1000)
}

export function inputHasData(control: AbstractControl): boolean {
    if (control.value.trim() === "") {
        return false
    }

    return control.status === "VALID"
}

export function getRect(element) {
    let rect = element.getBoundingClientRect()
    return {
        left: rect.left,
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.right - rect.left,
        height: rect.bottom - rect.top
    }
}

export function normalizeLanguage(inputLanguage: string): string {
    inputLanguage = inputLanguage || ""
    switch (inputLanguage) {
        case "1030":
            return "da"
        case "1044":
            return "nb"
        case "1033":
            return "en"
        default:
            return inputLanguage
    }
}

export function checkPasswordStrength(password: string): string {
    if (!password || password.length < 8) {
        return "Et passord må ha minst 8 tegn"
    }

    let stats = {
        upper: 0,
        lower: 0,
        digit: 0
    }

    for (let i = 0; i < password.length; i++) {
        let char = password.charAt(i)
        if (char >= "0" && char <= "9") {
            stats.digit++
        } else if (char >= "A" && char <= "Z") {
            stats.upper++
        } else if (char >= "a" && char <= "z") {
            stats.lower++
        }
    }

    if (stats.upper < 1) {
        return "Passordet må ha minst en stor bokstav"
    }

    if (stats.digit < 1) {
        return "Passordet må ha minst ett siffer"
    }

    if (stats.lower < 1) {
        return "Passordet må ha minst en liten bokstav"
    }

    return undefined
}

export function findByProp(array, prop, value) {
    for (let item of array) {
        let itemValue = item[prop]
        if (itemValue === value) {
            return item
        }
    }
    return undefined
}

export function trimValue(input): string {
    let value = input.value
    if (value === undefined) {
        value = ""
    }
    return value.trim()
}

export async function pause(milliseconds: number) {
    let promise = new Promise(resolve => {
        setTimeout(() => resolve(), milliseconds)
    })

    return promise
}

export function get(id: string) {
    return document.getElementById(id)
}

export function replaceAll(text, from, to) {
    text = text || ""
    let parts = text.split(from)
    return parts.join(to)
}

export function toDateString(date) {
    if (!date) {
        return ""
    }

    if (typeof date === "string") {
        date = new Date(date)
    }

    let day: string = date.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (date.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    let year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

export function buildContactName(contact) {
    let firstName = contact.firstName || ""
    let lastName = contact.lastName || ""
    let fullName = (firstName + " " + lastName).trim()
    return fullName
}

export function hasValue(obj: any) {
    return typeof obj !== 'undefined' && obj !== null;
}


export function markLanguageInMenu(popupService, language) {
    let check = "done"
    let submenu = "languages"
    switch (language) {
        case "nb":
            popupService.setMenuItemIcon(submenu, 0, undefined)
            popupService.setMenuItemIcon(submenu, 1, undefined)
            popupService.setMenuItemIcon(submenu, 2, check)
            break;
        case "en":
            popupService.setMenuItemIcon(submenu, 0, check)
            popupService.setMenuItemIcon(submenu, 1, undefined)
            popupService.setMenuItemIcon(submenu, 2, undefined)
            break;
        case "da":
            popupService.setMenuItemIcon(submenu, 0, undefined)
            popupService.setMenuItemIcon(submenu, 1, check)
            popupService.setMenuItemIcon(submenu, 2, undefined)
            break;
    }
}

export async function download(fileInfo) {
    let a = document.getElementById("data-sheet-download-link")
    a.innerText = fileInfo.fileName
    let binary = base64ToArrayBuffer(fileInfo.documentBody)
    let blob = new Blob([binary], { type: fileInfo.mimeType })
    var url = window.URL.createObjectURL(blob)
    a["href"] = url
    a["download"] = fileInfo.fileName
    a.click()
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}